import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/SEO';
import Layout from '../layout';
import Hero from '../components/Home/hero';
import AppSteps from '../components/Home/appStep';
import Footer from '../components/Footer';
import BlockQuote from '../components/Home/blockQuote';
import FaqList from '../components/FaqList';

const IndexPage = (props) => {
  const intl = useIntl();
  const homeFaqItems = [
    {
      question: 'What is DanfeBooks?',
      questionNe: 'डाँफेबुक्स के हो?',
      answer: {
        __html:
          'DanfeBooks is an online Hybrid HRM software combined with payroll with a premium service catering to Nepali businesses. <br /><br />DanfeBooks unique payroll system follows the Bikram Sambat (बिक्रम संवत) calendar first. Allowing you to easily auto-generate payslips each Nepali month or Gregorian month on your day of choice. <br /><br />This is the only software that provides integration of Nepali holiday automatically each year as they become available by the government.',
      },
      answerNe: {
        __html:
          'डाँफेबुक्स नेपाल कै पहिलो अनलाइन Hybrid HRM  सफ्टवेयर हो जसमा मानव संशाधन व्यवस्थापनको साथै कर्मचारीहरुको हाजिरीको विवरण व्यवस्थित रुपमा संचय गर्न सकिन्छ।<br /><br />डाँफेबुक्स नेपाली पात्रो (बिक्रम संवत प्रणाली) मा केन्द्रित छ।  यस विशेषताले गर्दा कर्मचारीहरुको मासिक तलब नेपाली महिनामा आफैले उत्पादन गर्न सक्छ। <br /><br />यसको अर्को विशेषता, नेपाल सरकारले सरकारले जारी गरेको सार्बजनिक बिदा हरेक बर्ष अध्यावधिक हुन्।',
      },
      open: true,
    },
    {
      question: 'What is hybrid HRM + payroll software?',
      questionNe:
        'Hybrid HRM + Payroll Software (मानव संशाधन व्यवस्थापन तथा आय भुक्तान किताव) भन्नाले के बुझिन्छ?',
      answer: {
        __html:
          'Hybrid HRM is otherwise known as Human resource management is is a model consisting of multi-features. The reason behind the hybrid is that instead of a single feature, it pools together multiple roles in one place within an organisation. This is especially effective for employees who perform more than one role.<br /><br />Moreover, payroll software allows you to efficiently manage your employees. Within a payroll software, you can calculate payments, taxes, holiday entitlements, access payslips and many others through easy automation. Payroll software saves time on HR errands allowing your focus to shift elsewhere in the business.',
      },
      answerNe: {
        __html:
          'यो मानव संशाधन व्यवस्थापन प्रणाली हाईव्रिड प्रणाली यस कारण हो कि यसको सेवा विशेषता धेरै छ।  एक संगठन मा कुनै एक कम्रचारीको कार्य धेरै हुन सक्छ, त्यो कर्मचारी को विविध कार्यको सरल तरिका ले रेखदेख गर्न सहायता गर्छ।<br /><br />साथै आय भुक्तानी  किताबले तपाई को काम सहज बनाउछ र समय बचत गर्दछ । कार्यालयको हरेक कर्मचारी को तलब, उनीहरु ले तिर्नु पर्ने कर, लिएको वा लिन बाकी बिदा को जानकारी सजिलै गर्नमा यो हाईव्रिड सफ्टवेयर राम्रो छ। ',
      },
      open: false,
    },
    {
      question: 'How does DanfeBooks automate payroll for Nepalese Businesses?',
      questionNe:
        'डाँफेबुक्सले आय/तलब भुक्तान हिसाब/किताब कसरी स्वचालित गर्दछ?',
      answer: {
        __html:
          'While most Payroll Software operates off the Gregorian Calendar, Danfe Books uses the Bikram Sambat. The reason behind this is accounting in Nepal follows the Bikram Sambat calendar. As the days in each month, is not always the same as previous, this allows to payroll to be automated easily. Similarly, as the proposed holidays change each year by the government, DanfeBooks makes it easier to follow by integrating payroll calculation.<br /><br />Likewise, integration of holidays suggested by the government is updated into the Danfebooks calendar and reviewed every new year.',
      },
      answerNe: {
        __html:
          'सर्वप्रथम, डाँफेबुक्स नेपाल सरकार बाट जारी गरिएको  बिक्रम सम्बत पात्रोमा आधारित छ। यस कारण नेपाली महिना अनुसार तलब भर्पाइ गर्न महिनाको अन्त्य कार्यदिनमा यो सोफ़्टवेयरले सघाउ पुर्याउछ। <br /><br />साथै हाजिरी र बिदा दिनको को ख्याल राखी तलब को हिसाब/किताब गर्छ।',
      },
      open: false,
    },
    {
      question: 'Why DanfeBooks offers free HRM and Payroll software?',
      questionNe: 'डाँफेबुक्सले नि:शुल्क प्रयोग उपलब्ध गराउनुको कारण?',
      answer: {
        __html:
          'Here at DanfeBooks, we care about startups and know that resources and funding are limited in the early days. Therefore, to help promote and support start-ups in Nepal, we offer a basic free HRM version for employees less than 10.<br /><br />However, if you want to have access to further features and have more employees, then we also offer a premium service.',
      },
      answerNe: {
        __html:
          'हामीलाई थाहा  छ - व्यापार को सुरुवात गर्न र सुरु गरेको की समय सम्म आर्थिक श्रोत र मानविय श्रोत सिमित हुन्छा।  हामी आफै पनि  त्यो समय पार गरेर आएका हौ।  त्यो परिस्थितिबाट अगाडी बढ्न र सफल हुनका लागि साना तथा घरेलु व्यावसायलाई  सहायोग पुग्योस भन्ने हाम्रो उद्देश्य अनुसार हामीले यो नि:शुल्क प्रयोगको सुबिधा तपाई माझ ल्याएका हौ। <br /><br />तर दश (१०) भन्दा बढी कर्मचारी रहेको संस्था वा कम्पनीले भने र अरु बिबिध सुबिधाको उपयोग गर्न परेमा सेवा शुल्क लाग्ने जानकारी गराउन चाहनछौ।',
      },
      open: false,
    },
  ];
  return (
    <Layout>
      <SEO
        meta={{
          title: intl.formatMessage({ id: 'title' }),
          desc: intl.formatMessage({ id: 'description' }),
          slug: `/`,
        }}
      />
      <Hero />
      <AppSteps />
      <div className="faq-wrap home-faq">
        <section className="site-container">
          <h2>{intl.formatMessage({ id: 'faq.title' })}</h2>
          <FaqList faqItems={homeFaqItems} />
        </section>
      </div>
      <Footer>
        <BlockQuote pageContext={props.pageContext} />
      </Footer>
    </Layout>
  );
};

export default IndexPage;
