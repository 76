import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

export default function hero() {
  const intl = useIntl();
  return (
    <>
      <div className="masthead js-masthead-fixed">
        <div className="masthead-contents">
          <div className="masthead-text js-masthead-text">
            <h1 className="logo">
              <img
                src="/logos/logo-line.svg"
                alt={`${intl.formatMessage({
                  id: 'logo.danfe',
                })} ${intl.formatMessage({ id: 'logo.books' })}`}
              />
              <small>{intl.formatMessage({ id: 'logo.tagline' })}</small>
            </h1>
            <p className="sub-lead">
              {intl.formatMessage({ id: 'landing.desc' })}
            </p>
            <p className="sub-lead">
              <a
                href="https://app.danfebooks.com/signup?utm_source=DanfeBooks&amp;utm_medium=LandingButton"
                className="masthead-buttons masthead-button-appreciate"
              >
                {intl.formatMessage({ id: 'landing.btnSignup' })}
              </a>
              {intl.formatMessage({ id: 'landing.or' })}
              <a
                href="https://app.danfebooks.com/login?utm_source=DanfeBooks&amp;utm_medium=LandingButton"
                className="masthead-buttons-link"
              >
                {intl.formatMessage({ id: 'landing.btnLogin' })}
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
