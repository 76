/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { v4 as uuidv4 } from 'uuid';

import './faq.scss';

function FAQ({ faqItems }) {
  const [supportFaqList, setSupportFaqList] = useState(faqItems);
  const intl = useIntl();
  const isNe = intl.locale === 'ne';

  const toggleFAQ = (index) => {
    setSupportFaqList(
      supportFaqList.map((faq, i) => {
        const item = faq;
        if (i === index) {
          item.open = !item.open;
        } else {
          item.open = false;
        }

        return item;
      }),
    );
  };

  return (
    <>
      {supportFaqList.map((faq, index) => (
        <article key={uuidv4()} className={`faq ${faq.open ? 'open' : ''}`}>
          <h3 className="faq-question" onClick={() => toggleFAQ(index)}>
            {isNe ? faq.questionNe : faq.question}
          </h3>
          <p
            className="faq-answer"
            dangerouslySetInnerHTML={isNe ? faq.answerNe : faq.answer}
          />
        </article>
      ))}
    </>
  );
}

export default FAQ;
