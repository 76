import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import ImgAddEmp from '../../Image/promo/addemp.svg';
import ImgAttendance from '../../Image/promo/attendance.svg';
import ImgTaxandPay from '../../Image/promo/taxandpayslip.svg';

export default function appSteps() {
  const intl = useIntl();
  return (
    <>
      <div className="masthead-placeholder" />
      <div className="mastapp-steps">
        <div className="app-steps-wrap site-container">
          <div className="app-steps app-steps-a">
            <div className="slogan">
              {intl.formatMessage({ id: 'landingBox1.title' })}
            </div>
            <h2>{intl.formatMessage({ id: 'landingBox1.action' })}</h2>
            <div className="app-steps-contents">
              <figure>
                <img src={ImgAddEmp} alt="Add employees illustation" />
              </figure>
              <p>{intl.formatMessage({ id: 'landingBox1.desc' })}</p>
            </div>
          </div>
          <div className="app-steps app-steps-b">
            <div className="slogan">
              {intl.formatMessage({ id: 'landingBox2.title' })}
            </div>
            <h2>{intl.formatMessage({ id: 'landingBox2.action' })}</h2>
            <div className="app-steps-contents">
              <p>{intl.formatMessage({ id: 'landingBox2.desc' })}</p>
              <figure>
                <img
                  src={ImgAttendance}
                  alt="Attendance recording illustation"
                />
              </figure>
            </div>
          </div>
          <div className="app-steps app-steps-c">
            <div className="slogan">
              {intl.formatMessage({ id: 'landingBox3.title' })}
            </div>
            <h2>
              {intl.formatMessage({ id: 'landingBox3.autoGenerate' })}
              <br />
            </h2>
            <div className="app-steps-contents">
              <figure>
                <img
                  src={ImgTaxandPay}
                  alt="Set date for payslip generation illustation"
                />
              </figure>
              <p>{intl.formatMessage({ id: 'landingBox3.desc' })}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
