import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';

export default function blockQuote(props) {
  const { pageContext } = props;
  const intl = useIntl();
  return (
    <>
      <blockquote className="news-block">
        <p>
          <strong>{intl.formatMessage({ id: 'blockQuote.goodNews' })}</strong>
          {intl.formatMessage({ id: 'blockQuote.desc' })}
        </p>
        {pageContext.intl.language !== 'en' ? (
          <cite>
            {intl.formatMessage({ id: 'blockQuote.forMore' })}
            <Link to="/press-release">
              {intl.formatMessage({ id: 'blockQuote.pressRelease' })}
            </Link>
            {intl.formatMessage({ id: 'blockQuote.read' })}
          </cite>
        ) : (
          <cite>
            {intl.formatMessage({ id: 'blockQuote.read' })}
            <Link to="/press-release">
              {intl.formatMessage({ id: 'blockQuote.pressRelease' })}
            </Link>
            {intl.formatMessage({ id: 'blockQuote.forMore' })}
          </cite>
        )}
      </blockquote>
    </>
  );
}
